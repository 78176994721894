import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */


 function renderImage(file,param){
   
  if (param === 'img-fluid') {return (<Img fluid = {file.node.childImageSharp.fluid}  />)}
  
  return(
    <Img fixed = {file.node.childImageSharp.fixed} className = {param}/>
  )

 }
 

 const Image = function (props) {

  return <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              extension
              relativePath
              childImageSharp {
              fixed (height: 350) {
                ...GatsbyImageSharpFixed
              }
              fluid (maxHeight: 520){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    `}
    render={(data) => {
   
      const image = data.images.edges.find(
        image => image.node.relativePath === props.image
      ) 
     
      return(renderImage(image,props.className))
    }}
  />
}



export default Image
