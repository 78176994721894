import React from 'react'
import Image from './image'
import SVG from './benefit-icons'
import catDecor from '../assets/img/cats-decor-light.svg'
import dogDecor from '../assets/img/dogs-decor-light.svg'


const Caption = (props) => {
  let img =''  
  if (props.data.type === 'cat'){
    img = catDecor
    } else {
      img = dogDecor
    }
    return (
    <div className="section__caption">
    <span id={"dogs-"+props.data.category}>{props.data.name}</span>
    <div className="section__decor" aria-hidden="true">
      <img src={img} alt={props.data.name}/>
      <img src={img}  alt={props.data.name}/>
    </div>  
  </div>
    )}


const ContraIndications = (props) => {
return (
  <div className="section__part contraIndications">
  <h4 className={"h3 text-"+props.data.category+" text-center text-sm-left mb-2"}>KONTRAENDİKASYONLAR</h4>
      <ul>
      {props.data.contraindications.map((item,key)=> <li key={key}>{item}</li>)

    }
      </ul>
</div>
)}


const PackageImage = (props) =>{
  

  return (
    <div className='flex'>
    <Image className='img-fluid' image={props.data.image} />
    </div>

  )


}

const Contact = (props) =>{

  return(
  <div className="mb-8">
    <div className="call2action">
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="72" height="72" className="call2action__icon mb-4">
          <title>Phone</title>
          <defs>
              <path id="a" d="M1136 909a4 4 0 0 1 4 4 68 68 0 0 1-68 68 4 4 0 0 1-4-4v-14a4 4 0 0 1 4-4l14-2a4 4 0 0 1 4 1l9 9a60 60 0 0 0 27-27l-9-9a4 4 0 0 1-1-4l2-14a4 4 0 0 1 4-4z"/>
          </defs>
          <use fill="currentColor" xlinkHref="#a" transform="translate(-1068 -909)"/>
      </svg>
      <h4 className="h5 mb-1">Are you interested in this product?</h4>
      <p className="call2action__description text-muted mb-2">Please contact our sales manager</p>

      <div className="text-center mb-4">
          <h5 className="h6 text-hypoallergenic">Richard Macek, DVM</h5>
          <p className="small">
              email: <strong><a href="mailto:richard.macek@vafo.cz" className="text-dark">richard.macek@vafo.cz</a></strong><br/>
              phone: <strong><a href="tel:+420731952099" className="text-nowrap text-dark">+420 731 952 099</a></strong>
          </p>

      </div>

      <a href="/contact" className="btn btn--gradient-hypoallergenic btn--lg">Contact us</a>
  </div>
</div>


  )

}


const Teaser = (props) => (
    <div className="section__part">
    <h3 className={"h2 text-"+props.data.category+" text-center text-sm-left mb-2 d-none d-sm-block"}>{props.data.name}</h3>

    <p className="perex">{props.data.abstract}</p>
</div>

)


const Indications = (props) => (
    <div className="section__part indications">
        <h4 className={"h3 text-"+props.data.category+" text-center text-sm-left mb-2"}>ENDİKASYONLAR</h4>
        <ul>
        {props.data.indications.map((item,key)=> <li key={key}>{item}</li>)

        }
        </ul>
    </div>
)

const Package = (props) => (
    <div className="col-lg-3 ml-auto text-center text-sm-left mt-6"> 
        <h3 className={"h3 text-"+props.data.category+" mb-2"}>{props.data.ingredients}</h3>
          <div className="package">
            <Image image = {props.data.image} className="img-fixed"/>
            
          </div>
                        
          <div>              
            <a href={"/urunler/"+props.data.type+"-"+props.data.category} className={"btn btn--lg btn--gradient-"+props.data.category+" px-6"}>Detaylı Bilgi</a>                
          </div>              

    </div>                
)





const BenefitsStatic = (props) => (
   <div className="section__part">
   <h4 className={"h3 text-"+props.data.category+" mb-2 text-center text-sm-left"}>FAYDALARI</h4>
     <div className="benefits pt-2">
      <div className="row">
        {props.data.benefits.map((benefit,i)=>{
          return (
            <div className="col-6 col-md-4 col-xxl-3 mb-4" key={i}>
              <div className="benefits__item">
              <SVG width="64" height="64" viewBox="0 0 64 64" className="benefits__icon" name={benefit.icon}/>
                                    <h5 className="benefits__title">{benefit.iconTitle}</h5>
                                    <p className="benefits__description">{benefit.desc}</p>
              </div>
            </div>
          )

        })}
      
      </div>
     </div>
   </div>



)







export {Teaser, Indications, Package, Caption, BenefitsStatic,PackageImage, Contact, ContraIndications}



 